.list {
    margin: auto;
    height: 7rem;
    width: 200px;
    /* background-color: rgba(0, 30, 149, 0.147); */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
    background-color: #749af45e;
    border-radius: .5rem;
    cursor: pointer;
}
.list:hover {
    background-color: #24bdf9;
}

.list img {
    height: 70px;
    padding-bottom: .25rem;
}

.list p {
    font-weight: bold;
    margin-bottom: 0;
}