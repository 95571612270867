.heading {
    margin: 5rem auto 2rem auto;
    text-align: center;
}

.rentingServices {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    margin: auto auto 2rem auto;
}

.flexCol {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.rentingServiceCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 180px;
    margin: 10px;
    padding: 1rem 1rem 1rem 1rem;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    background-color: #a9b7f6c1;
}

.rentingServiceIcon {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 1rem;
    background-color: #d4dbfac1;

}

.rentingServiceIcon img {
    max-height: 90%;
    max-width: 90%;
    margin: auto;
    align-self: center;
}

.rentingServiceName h3{
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

.rentingServiceButton {
    text-align: center;
    margin-bottom: 0;
}

.rentingServiceButton a {
    color: #fff;
    background-color: #0040ff;
    padding: .25rem 1rem;
    border-radius: 5px;
}
@media screen and (max-width: 500px) {
    .rentingServiceCard{
        width: 90%;
    }
    .rentingServiceIcon{
        width: 200px;
        height: 200px;
    }
}