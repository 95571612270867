.cont {
    width: 80%;
    background-color: rgb(255, 255, 255);
    margin: auto;
    border-radius: .5rem;
    box-shadow: 1px 1px 1rem rgba(0, 0, 0, 0.504);
    margin-top: -50px;
    z-index: 6;
    position: relative;
    padding: 1rem;
}

.cont ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    gap: 1rem;
}

@media screen and (max-width: 500px) {
    .cont ul {
        flex-direction: column;
    }


}