.modalButton {
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: 0 .5rem;
    padding: .25rem .5rem;
}
.modalButton:hover{
    cursor: pointer;
}

.modalButton2 {
    min-width: 250px !important;
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: .25rem .5rem;
}

.flexCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.flexRow {
    /* margin: 1rem auto; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.flexRowCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin: auto;
}

.form {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

}

.normalInput {
    min-width: 300px;
}

.box {
    max-height: 80vh;
    overflow-y: auto;
}
@media screen and (max-width: 500px) {
    .box{
        width: 90% !important;
    }
}
.heading {
    text-align: center;
    background-color: aliceblue;
    font-weight: bold;
    padding: 2rem;
}

.heading2 {
    text-align: center;
    padding: 1rem;
}

.para {
    margin: 1rem auto;
    background-color: aliceblue;
    width: 70%;
    padding: 2rem;
    margin-bottom:5rem;
}
.reach{
    display: flex;
    justify-content: space-between;
}
.socials{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-right: 2rem;
}
.icons :hover{
    cursor: pointer;
}
    
@media screen and (max-width: 500px) {
    .para {
        width: 90%;
        padding: 2rem 1.5rem;
    }
    .reach{
        flex-direction: column;
        align-items: center;
    }
}