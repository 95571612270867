/* Messages.module.css */
.ul{
    margin: 0;
    padding: 0;
}
.solved {
    padding: 10px;
    border: 1px solid #ccc;
    margin: 10px;
    background-color: #fff;
    list-style: none;   
    background-color: lightgreen;
}

.pending {
    padding: 10px;
    border: 1px solid #ccc;
    margin: 10px;
    background-color: #fff;
    list-style: none;
    background-color: rgb(251, 169, 183);
}
.field {
    font-weight: bold;
}

/* .solved {
    color: green;
    background-color: lightgreen;
} */

/* .pending {
    color: red;
    background-color: pink;
} */

.Solvebutton {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.Solvebutton:hover {
    background-color: #0056b3;
}
.Pendingbutton {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #b52e57;
    color: #fff;
    border: none;
    cursor: pointer;
}

.Pendingbutton:hover {
    background-color: #702b3c;
}
.callLink{
    background-color: darkgreen;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: .5rem;
    float: right;
}