.navCont {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    z-index: 5;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.navCont img {
    height: 6rem;
}

.ulist {
    list-style: none;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    font-weight: bold;
    color: white;
}

.ulist li {
    cursor: pointer;
    margin: 1rem auto;
}

.Ham {
    display: none;
}

@media screen and (max-width: 500px) {
    .ulist {
        display: none;
    }

    .navCont {
        width: 100%;
        margin: 1rem auto;
        filter: drop-shadow(1px 1px 1rem rgba(0, 0, 0, 0.504));
    }

    .navCont img {
        height: 3rem;
    }

    .Ham {
        display: flex;
        margin-right: 1rem;
    }
}