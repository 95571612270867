.App {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.wa {
    position: fixed;
    z-index: 10000;
    background-color: transparent;
    border: none;
    right: 0;
    bottom: 5rem;
}

.wa img {
    width: 70px;
}

.wa:hover {
    cursor: pointer;
}

.amc {
    text-align: center;
    background-color: rgb(0, 89, 255);
    color: white;
    font-size: 1.2rem;
}

.tnc {
    font-size: 0.9rem;
    color: rgb(255, 140, 0);
}

/* HomePage.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.heading {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

.infoCard {
    background-color: #504400;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    width: 80%;
    margin: 2rem auto;
}

.infoTitle {
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
    font-weight: bold;
}

.infoText {
    font-size: 18px;
    color: #fff;
    background-color: rgba(240, 248, 255, 0.392);
    border-radius: .5rem;
    margin-bottom: 10px;
    padding: 1rem;
}
.infoText1 {
    width: max-content;
    font-size: 12px;
    color: #fff;
    background-color: rgba(0, 45, 142, 0.781);
    border-radius: .5rem;
    margin-bottom: 10px;
    padding: .5rem;
    margin: 2rem auto;
    text-align: center;
}

.infoText:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 500px) {
    .amc {
        padding: .5rem;
        font-size: 1rem;
    }
    .infoText{
        font-size: 1rem;
    }
}