.navbar {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.navbar h1 {
    font-weight: bold;
    text-transform: uppercase;
}
.buttonCont {
    height: max-content;
    margin: auto;
    margin-right: auto;
    float: right;
    margin-right: 1rem;
    gap: 1rem;
    display: flex;
}
.buttonsGroup{
    display: flex;
    gap: 1rem;
    margin: 1rem;
}
.heading2{
    padding: .5rem;
    background-color: cadetblue;
    font-size: 1.5rem;
    font-weight: bold;
}
.image{
    height: 200px !important;
    width: auto !important;
}
.imgCont{
    list-style: none;
    background-color: rgb(173, 213, 248);
    margin: 1rem 2rem;
    border-radius: 1rem;
    margin-left: 0;
    padding: 1rem;
}
.actionCont{
    margin: 1rem;
}