/* App.css */

/* Style the image container */
.imageContainer {
    position: relative;
    z-index: 4;
}

.imageContainer img {
    min-height: 590px;
    min-width: 100%;
}

/* Add the blackish overlay */
.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.8));
    /* Adjust the opacity by changing the last value (0.5) */
    pointer-events: none;
    /* Allows clicking through the overlay to the underlying content */
    z-index: 1;

}

@media screen and (max-width: 500px) {
    .imageContainer img {
        min-height: auto;
        min-width: auto;
    }
}