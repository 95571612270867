.heading {
    text-align: center;
    background-color: aliceblue;
    font-weight: bold;
    padding: 2rem;
}
.heading2{
    text-align: center;
    padding: 1rem;
}
.para {
    margin: 1rem auto;
    background-color: aliceblue;
    width: 70%;
    padding: 2rem;
}
@media screen and (max-width: 500px) {
    .para {
        width: 90%;
        padding: 2rem 1.5rem;
    }
}