.FooterCont {
    padding: 2rem 3% 1rem 3%;
    background-color: #00091b;
    color: white;
    /* position: absolute;
    bottom: 0 !important; */
    width: 100%;

}

.logoCont {
    display: flex;
    justify-content: space-between;
}

.logoCont img {
    width: 200px;
}

.cp {
    display: flex;
    gap: 1rem;
}

.cp p {
    font-size: .7rem;
    color: aliceblue;
    align-self: center;
    margin: 0;
}

.centerFlex {
    align-self: center;
    display: flex;
    gap: .5rem;
}

.center {
    align-self: center;
}

.FooterCont hr {
    color: gray;
}

.hr {
    width: 100%;
    margin: 2rem 0;
    border: .5px solid rgba(128, 128, 128, 0.338);
}

.FooterCont ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    font-weight: bold;
    padding: 0;
}

.FooterCont ul li {
    cursor: pointer;
}

.responsive {
    display: none;
}

@media screen and (max-width: 500px) {
    .FooterCont ul {
        display: flex;
        font-weight: 700;
        gap: 1rem;
        list-style: none;
        padding: 0;
        font-size: .7rem;
    }

    .logoCont img {
        width: auto;
        height: 35px;
    }

    .center p {
        display: none;
    }

    .responsive {
        display: block;
    }

    .responsive p {
        font-size: .7rem;
        padding: 0;
        margin: 0;
        text-align: center;
    }

    .hr {
        margin: 1rem 0;
    }

}