.modalButton {
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: 0 .5rem;
    padding: .25rem .5rem;
}
.modalButton:hover{
    cursor: pointer;
}

.modalButton2 {
    min-width: 250px !important;
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: .25rem .5rem;
}

.flexCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.flexRow {
    /* margin: 1rem auto; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.flexRowCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin: auto;
}

.form {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

}

.normalInput {
    min-width: 300px;
}

.box {
    max-height: 80vh;
    overflow-y: auto;
}
@media screen and (max-width: 500px) {
    .box{
        width: 90% !important;
    }
}