.card {
    width: 40%;
    background: linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84));
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgb(84 90 106);
    margin: auto auto 2rem auto;
    text-align: center;
}

.smallText,
.title,
.desc {
    font-weight: 600;
}

.title,
.desc {
    margin: 8px 0;
}

.smallText {
    color: #488aec;
    font-size: 14px;
}

.title {
    color: #fff;
    font-size: 26px;
    line-height: 26px;
}

.desc {
    color: rgb(151 153 167);
    font-size: 13px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: .5rem 1rem;
    text-decoration: none;
}

.button:first-child {
    box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
    background-color: #488aec;
}

.button:last-child {
    box-shadow: 0 4px 6px -1px #0b162531, 0 2px 4px -1px #0e192b17;
    background-color: #fff;
}

.icon {
    height: 25px;
    width: 25px;
}

.icon svg {
    width: 100%;
    height: 100%;
}

.icon svg:last-child {
    fill: #000
}

.smallTebuttonTextxt {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.smallTebuttonTextxt span:first-child {
    font-size: 12px;
    font-weight: 600;
}

.google span:first-child {
    color: rgb(219 206 253);
}

.apple span:first-child {
    color: rgb(81 87 108);
}

.google span:last-child {
    color: #fff;
    font-weight: 800;
}

.apple span:last-child {
    color: #000;
    /* font-weight: 800; */
    margin-bottom: 0.15rem;
}
@media screen and (max-width: 500px) {
    .card{
        width: 90%;
    }
}