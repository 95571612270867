/* Hamburger.css */
.hamburger-container {
    position: relative;
    display: inline-block;
}

.hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: .25rem;
}

.bar {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
}

.hamburger.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(-45deg);
}

.hamburger.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
}

.menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 150px;
    border-radius: 5px;
    z-index: 100000;
}

.menu ul {
    list-style-type: none;
    padding: 0;
}

.menu ul li {
    width: 100%;
    padding: 1rem;
    cursor: pointer;
}

.menuLi:hover {
    background-color: #94baf8;
}