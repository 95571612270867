/* Container styles */
.parentCont {
    min-height: 60vh;
}

.parentCont h1 {
    padding: .5rem 3rem;
    font-weight: bold;
    background: aliceblue;
    display: flex;
    justify-content: space-between;
}

.Container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: auto;
}

.flexRow {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.flexRow img {
    width: 500px;
    margin-top: 2rem;
    max-height: 225px;
}

/* Card styles */
.Card {
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    height: max-content;
    margin: 1rem auto;
    display: flex;
    justify-content: space-evenly;
}


.cardDiv:hover {
    background-color: #a9c1ff59;
    border-radius: 1rem;
}

.warranty {
    padding: .25rem .5rem;
    border-radius: .25rem;
    background: #06bc0059;
    height: max-content;
    margin: auto auto 1rem auto;
    text-align: center;
}

/* Title styles */
.Title {
    font-size: 24px;
    color: #4285f4;
}

/* Service styles */
.Service {
    margin: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 6px;
    transition: background-color 0.3s ease-in-out;
}

.Service:hover {
    background-color: #e6e6e6;
    transform: scale(1.03);
}

/* Service name styles */
.ServiceName {
    font-size: 18px;
    color: #333;
}

/* Service details styles */
.ServiceDetails {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

/* Discount price styles */
.DiscountPrice {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Initial price styles */
.InitialPrice {
    font-size: 16px;
    color: #999;
    text-decoration: line-through;
    margin-right: 8px;
}

/* Final price styles */
.FinalPrice {
    font-size: 20px;
    color: #333;
    font-weight: bold;
}

/* Discount styles */
.Discount {
    color: #555;
    font-size: 14px;
}

.image {
    max-width: 300px;
    height: auto;
}

.imageCont {
    height: 250px;
    max-height: 250px;
    padding: 1rem;
}

@media screen and (max-width: 500px) {
    .flexRow {
        flex-direction: column;
        gap: 1rem;
    }

    .flexRow img {
        width: 100%;
    }

    .parentCont h1 {
        padding: 1rem;
    }

    .Heading {
        font-size: 1.5rem !important;
    }

    .Card {
        min-height: max-content;
        flex-direction: column;
        gap: 2rem;
    }
}