/* FloatingButton.css */
.floatingButtonContainer {
    position: fixed;
    bottom: 7rem;
    right: 2rem;
    z-index: 1000;
}

.floatingButton {
    background-color: #ff3c00;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}
.floatingButton:active{
    border: none;
}

.floatingButton:hover {
    background-color: #0056b3;
}

.floatingOptions {
    position: absolute;
    bottom: 2.5rem;
    right: -1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
}



.download {
    display: flex;
    width: 150px;
    padding: .25rem .5rem;
    margin-bottom: .5rem;
    border-radius: .5rem;
    align-self: first baseline;
    background-color: rgb(170, 125, 255);
}

.download:hover {
    cursor: pointer;
    background-color: #8fbff1;
}

.download a {
    margin: auto;
    color: inherit;
}

.download .icons {
    margin: auto;
}

.appointment {
    display: flex;
    width: 150px;
    padding: .25rem .5rem;
    margin-bottom: .5rem;
    border-radius: .5rem;
    align-self: first baseline;
    background-color: rgb(125, 175, 255);
}

.appointment:hover {
    cursor: pointer;
    background-color: #8fbff1;
}

.appointment a {
    margin: auto;
    color: inherit;
}

.appointment .icons {
    margin: auto;
}

.wa {
    width: 150px;
    padding: .25rem .5rem;
    margin-bottom: 1.5rem;
    border-radius: .5rem;
    display: flex;
    background-color: #25d366;
    align-self: first baseline;
}

.wa:hover {
    cursor: pointer;
    background-color: #00b342;
}

.wa a {
    margin: auto;
    color: inherit;
}

.wa .icons {
    margin: auto;
}