.heading {
    background: #def0ff;
    padding: .5rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
}

.buttonCont, .heading Button {
    height: max-content;
    margin: auto;
    margin-right: auto;
    float: right;
    margin-right: 1rem;
    gap: 1rem;
    display: flex;
}

.card {
    margin: 1rem;
    padding: 1rem;
    background: aliceblue;
    border-radius: .5rem;
}

.card ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.card input {
    height: max-content;
    margin: auto;
    width: 200px;
}

.card li {
    display: flex;
    gap: 1rem;
    background: #00000026;
    padding: 1rem;
}


.flexCol {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.flexRow {
    display: flex;
    gap: 1rem;
}

.displayImage {
    height: 100px;
    width: auto;
}
.navbar {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.navbar h1 {
    font-weight: bold;
    text-transform: uppercase;
}