/* SearchBar.module.css */
.searchHeading {
    z-index: 5;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.searchHeading h1 {
    color: white;
    font-size: 3rem;
    letter-spacing: 1px;
    width: max-content;
}

@media screen and (max-width: 500px) {
    .searchHeading {
        top: 20%;
        z-index: 0;
        margin-top: 1rem;
        filter: drop-shadow(1px 1px 1rem rgb(208, 208, 208));
        width: inherit;
    }

    .searchHeading h1 {
        font-size: 1.1rem;
        color: black;
    }
}

.searchCont {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    gap: 1rem;
    border-radius: .5rem;
}

.searchCont span {
    display: inherit;
}

.searchCont img {
    width: 30px;
    margin: auto;
    vertical-align: middle;
}

.searchCont input {
    border: none;
    outline: none;
    width: 400px;
    padding: 0.5rem;
    font-size: 1rem;
}

/* Styles for search results */
.resultsContainer {
    width: 68%;
    max-height: 120px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 10;
    top: 102%;
    left: 65.5%;
    transform: translateX(-50%);
    border-radius: 0.5rem;
}

/* Style for each individual search result */
.searchResult {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
}

.searchResult:hover {
    background-color: #f0f0f0;
}

a:hover {
    text-decoration: none;
}

.searchCont {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: .5rem 1rem;
    gap: 1rem;
    border-radius: 1rem;
}

.flex {
    display: flex;
    gap: 1rem;
}

.flag {
    font-size: 2rem;
    padding-left: 1rem;
}

.city {
    background-color: white;
    width: 200px;
    color: white;
    border-radius: .5rem;
}

.delhi {
    color: black;
    font-size: 1.5rem;
    margin: auto;
    padding-top: .5rem;
}

@media screen and (max-width: 500px) {
    .resultsContainer {
        width: 100%;
    }

    .searchCont input {
        width: auto;
    }

    .searchCont img {
        width: 30px;
        height: auto;
    }

    .flex {
        transform: scale(.75);
    }

    .resultsContainer {
        width: 55%;
        margin-left: 2rem;
    }
}