.cont{
    background-color: blueviolet;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    z-index: 10000;
}
.inner{
    width: 90%;
    margin: auto;
    padding: 1rem;
    background-color: azure;
}
/* Cart.module.css */
.cont {
    background-color: #002b6f5f;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: auto;
    font-family: Arial, sans-serif;
}

.inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inner>div {
    margin-right: 10px;
}

b {
    font-weight: bold;
}

i {
    font-style: italic;
}

Button {
    background-color: #4285f4;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

Button:hover {
    background-color: #0d47a1;
}