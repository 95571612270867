.modalButton {
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: 0 .5rem;
    padding: .25rem .5rem;
}

.modalButton:hover {
    cursor: pointer;
}

.modalButton2 {
    min-width: 250px !important;
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: .25rem .5rem;
}

.flexCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.flexRow {
    /* margin: 1rem auto; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.flexRowCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin: auto;
}

.form {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

}

.normalInput {
    min-width: 300px;
}

.box {
    max-height: 80vh;
    overflow-y: auto;
}
.cartCont{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.card{
    border-radius: .5rem;
    height: 150px;
    display: flex;
    gap: 1rem;
    background-color: rgb(201, 212, 255);
    padding: .5rem;
    justify-content: space-between;
}

.image{
    height: 100px;
    width: 100px;
    padding: .2rem;
}
.card img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: .5rem;

}
@media screen and (max-width: 500px) {
    .box {
        width: 90% !important;
    }
}