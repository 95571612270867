.firstBox h1{
    display: none;
}
@media screen and (max-width: 500px) {
    .firstBox h1{
        display: block;
    }
    .firstBox {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        margin-top: 12rem;
        justify-content: center;
        text-align: center;
    }
}