.modalButton {
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: 0 .5rem;
}
.modalButton2 {
    min-width: 250px !important;
    width: max-content;
    height: max-content;
    font-size: .9rem;
    padding: .25rem .5rem;
}
.flexCol{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.flexRow{
    /* margin: 1rem auto; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}