.css-sox5kk-MuiBackdrop-root {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.06) !important;
    -webkit-tap-highlight-color: transparent;
}

.css-um19vb {
    position: fixed;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(57, 83, 145, 0.09) !important;
}

.cartIns {
    margin-left: 4rem;
    font-size: .8rem;
    font-weight: bold;
    color: gray;
}